import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";
import ReactContentfulImage from "react-contentful-image";

import ImageSizes from "@/utils/helpers/images";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./LocationCard.module.scss";

const ConditionalLinkWrapper = ({ isDesktop, wrapper, children }) => {
  return isDesktop ? children : wrapper(children);
};

const LocationCard = ({
  thumbnail,
  webName,
  address,
  clubDetailPageURL,
  city,
  state,
  zip,
  phoneNumber,
  featuredAmenities,
  isDesktop,
  description,
}) => {
  const url = `${clubDetailPageURL}`;
  return (
    <div className={`col-lg-5 col-md-16`}>
      <div
        className={`${styles.LocationCardContainer} d-lg-block d-md-flex d-sm-flex`}
      >
        <div className={`${styles.imageWrap} d-lg-block d-md-none d-sm-none`}>
          <Link href={url}>
            <a aria-label="imageWrap" tabIndex={-1}>
              <ReactContentfulImage
                alt={description}
                sizes={ImageSizes.locationCard}
                src={thumbnail}
                title={webName}
              />
            </a>
          </Link>
        </div>

        <div className={styles.bodyWrapper}>
          <Link href={url}>
            <a>
              <p className={styles.title}>
                <span>{webName}</span>
              </p>{" "}
            </a>
          </Link>
          <ConditionalLinkWrapper
            isDesktop={isDesktop}
            wrapper={(children) => (
              <Link href={url}>
                <a>{children}</a>
              </Link>
            )}
          >
            <>
              <p className={styles.address}>
                {address}
                <br />
                {`${city}, ${state} ${zip}`}
                <br />
                <span className="d-lg-block d-md-none d-sm-none">
                  {phoneNumber}
                </span>
              </p>

              {featuredAmenities && (
                <div
                  className={`d-lg-block d-md-none d-sm-none ${styles.featuredAmenitiesContainer}`}
                >
                  <p className={styles.amenitiesTitle}>Featured Amenities</p>
                  <ul>
                    {featuredAmenities.slice(0, 2).map((amenity) => (
                      <li key={amenity}>{amenity}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          </ConditionalLinkWrapper>
        </div>

        <div className={`${styles.mobileImageWrapper} d-lg-none d-md-block`}>
          <Link href={url}>
            <a>
              <img
                alt="responsiveThumbnail"
                className={styles.responsoveThumbnail}
                src={`https://${thumbnail}`}
              />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

LocationCard.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  clubDetailPageURL: PropTypes.string.isRequired,
  description: PropTypes.string,
  featuredAmenities: PropTypes.array,
  isDesktop: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  region: PropTypes.shape({
    regionUrlName: PropTypes.string.isRequired,
  }),
  state: PropTypes.string.isRequired,
  subRegion: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subregionUrlName: PropTypes.string.isRequired,
  }),
  thumbnail: PropTypes.string.isRequired,
  webName: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
};

export default withScreenDimensions(LocationCard);
